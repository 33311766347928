// 引入 axios
import axios from "axios";

// let base = "http://localhost:8087";

let base = 'https://task.bjaiyj.cn/web-api';

//传送json格式的get请求
export const getRequest = (url, params) => {
  return axios({
    method: "get",
    url: `${base}${url}`,
    params: params,
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
  });
};

export const postRequest = (url, params) => {
  return axios({
    method: "post",
    url: `${base}${url}`,
    data: params,
    headers: {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    },
  });
};
